export const action = {
  ANALYTICS_SHOW_LOADER: "ANALYTICS_SHOW_LOADER",
  ANALYTICS_HIDE_LOADER: "ANALYTICS_HIDE_LOADER",
  ANALYTICS_FETCHED: "ANALYTICS_FETCHED",
  ANALYTICS_SET_PAGE: "ANALYTICS_SET_PAGE",
  ANALYTICS_SET_TOTAL: "ANALYTICS_SET_TOTAL",
  ANALYTICS_TYPES_FETCHED: "ANALYTICS_TYPES_FETCHED",
};

export const ANALYTICS_TYPES = [
  {
    key: "Preview.TenantTracking.Dashboard.View",
    label: "Preview.TenantTracking.Dashboard.View",
  },
  {
    key: "Preview.TenantTracking.Offer.Removed",
    label: "Preview.TenantTracking.Offer.Removed",
  },
  {
    key: "Preview.TenantTracking.Offer.Selected",
    label: "Preview.TenantTracking.Offer.Selected",
  },
  {
    key: "Preview.TenantTracking.Offer.Viewed",
    label: "Preview.TenantTracking.Offer.Viewed",
  },
  {
    key: "Preview.TenantTracking.PageView",
    label: "Preview.TenantTracking.PageView",
  },
  {
    key: "Preview.TenantTracking.PlanList.Viewed",
    label: "Preview.TenantTracking.PlanList.Viewed",
  },
  { key: "ShortUrl.Track.Resolve", label: "ShortUrl.Track.Resolve" },
  {
    key: "TenantTracking.Checkout.AuthorizedOffers",
    label: "TenantTracking.Checkout.AuthorizedOffers",
  },
  {
    key: "TenantTracking.Checkout.Started",
    label: "TenantTracking.Checkout.Started",
  },
  {
    key: "TenantTracking.Checkout.ViewAuthorization",
    label: "TenantTracking.Checkout.ViewAuthorization",
  },
  {
    key: "TenantTracking.Dashboard.View",
    label: "TenantTracking.Dashboard.View",
  },
  { key: "TenantTracking.Login", label: "TenantTracking.Login" },
  {
    key: "TenantTracking.Offer.Removed",
    label: "TenantTracking.Offer.Removed",
  },
  {
    key: "TenantTracking.Offer.Selected",
    label: "TenantTracking.Offer.Selected",
  },
  { key: "TenantTracking.Offer.Viewed", label: "TenantTracking.Offer.Viewed" },
  { key: "TenantTracking.PageView", label: "TenantTracking.PageView" },
  {
    key: "TenantTracking.PlanList.Viewed",
    label: "TenantTracking.PlanList.Viewed",
  },
  { key: "tracked_request", label: "Tracked Request" },
];
