
const initialState = {
  records: {},
  pages: {},
};

export default function companyEventsReducer(state, action) {
  switch(action.type) {
    case 'COMPANY_EVENTS_LOADING': {
      return {...state, pages: {...state.pages, [action.query.page]: null}};
    }

    case 'COMPANY_EVENTS_LOADED': {
      const ids = action.response.map(({id}) => id);

      return {...state,
              pages: { ...state.pages, [action.query.page]: ids },
              pageInfo: action.pageInfo,
              records: {
                ...state.records,
                ...action.response.reduce(
                  (acc, event) => ({...acc, [event.id]: event}),{}
                )
              },
             };
    }

    default:
      return state || initialState;
  }
}
