export const TRIGGER_OWNER_TYPE = {
  global: 'global',
  dealer: 'dealer',
  user: 'user',
  company: 'company',
};

export const triggerOwnerTypes = Object.values(TRIGGER_OWNER_TYPE);

export const TRIGGER_OWNER_TYPE_OPTIONS = [
  { key: 'global', label: 'Global' },
  { key: 'dealer', label: 'Dealer' },
  { key: 'user', label: 'User' },
  { key: 'company', label: 'Company' },
];

export const TRIGGET_ACTION_TYPE_OPTIONS = [
  { key: 'assignToDealer', label: 'Assign to Dealer' },
  { key: 'email', label: 'Email' },
  { key: 'webhook', label: 'Webhook' },
];
