import { putIn } from '../utils';

const initialState = {
  schemas: {
    offers: {},
    documents: {},
    email: {},
  },
  offers: {
    pages: {},
    records: {},
    publishErrors: {},
    byCompany: {},
  },
  productsOffers: {
    pages: {},
    records: {},
  },
  documents: {
    pages: {},
    records: {},
    byHash: {},
  },
  companies: {
    pages: {},
    records: {},
  },
  emailTemplates: {
    pages: {},
    records: {},
  },
  activations: {
    pages: {},
    records: {},
    mine: [],
    moreInfo: {
      index: 0,
    },
  },
  recommendations: {
    pages: {},
    records: {},
  },
  customFields: {
    pages: {},
    records: {},
    references: {},
    byPurpose: {},
  },
  addons: {
    byCompany: {},
    byOffer: {},
  },
  emails: {
    pages: {},
    records: {},
    byMeta: {
      activation_id: {},
      activation_ids: {},
    },
  },
  sms: {
    pages: {},
    records: {},
    byMeta: {
      activation_id: {},
      activation_ids: {},
    },
    byRecipient: {},
  },
  affiliates: {
    pages: {},
    records: {},
  },
  signupFields: {
    byCompany: {},
  },
  people: {
    pages: {},
    records: {},
  },
};

export function setPage(state, collection, page, ids, pageInfo, isLoading = false, isFailed = false) {
  return {
    ...state,
    [collection]: {
      ...state[collection],
      pages: {
        ...state[collection].pages,
        [page]: ids,
      },
      pageInfo,
      isLoading,
      isFailed,
    },
  };
}

export function addRecords(state, collection, records) {
  return {
    ...state,
    [collection]: {
      ...state[collection],
      records: {
        ...state[collection].records,
        ...records,
      },
    },
  };
}

export const setDataState = (state, { path, data }) => {
  if (!path) {
    return { ...state, ...data };
  }

  return {
    ...state,
    [path]: {
      ...state[path],
      ...data,
    },
  };
};

export const setLoadingState = (state, path) => {
  const data = {
    isLoading: true,
    isFailed: false,
  };

  return setDataState(state, { path, data });
};


export const setFailedState = (state, path) => {
  const data = {
    isLoading: false,
    isFailed: true,
  };

  return setDataState(state, { path, data });
};

export default function dataReducer(state, action) {
  switch (action.type) {
    case 'SCHEMA_LOADED':
      return {
        ...state,
        schemas: {
          ...state.schemas,
          [action.module]: {
            ...state.schemas[action.module],
            [action.collection]: action.schema,
          },
        },
      };

    case 'OFFERS_LOADING':
      return setPage(state, 'offers', action.query.page, null);

    case 'OFFERS_LOADED':
    {
      const ids = action.offers.map(offer => offer.id);
      const records = action.offers.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'offers', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'offers', records);
    }
    
    case 'PRODUCTS_OFFERS_LOADING':
      {
        return setPage(state, 'productsOffers', action.query.page, null, {}, true);
      }

    case 'PRODUCTS_OFFERS_LOADED':
      {const records = action.productsOffers.reduce((r, o) => ({ ...r, [o.id]: o }), {});
        return {
        
        ...state,
          productsOffers: { records: { ...records } }
    }}

    case 'OFFER_LOADED':
      return addRecords(state, 'offers', { [action.id]: action.offer });

    case 'OFFER_UPDATED':
      return addRecords(state, 'offers', { [action.id]: action.offer });

      /* FIXME: this breaks the way the 'listing' currently works. */
      // case 'OFFER_DELETED':
      //   return clearRecord(state, 'offers', action.id);
    case 'OFFER_PUBLISH_FAILED':
      return {
        ...state,
        offers: {
          ...state.offers,
          publishErrors: {
            ...state.offers.publishErrors,
            [action.id]: action.errors,
          },
        },
      };

    case 'OFFER_CLEAR_PUBLISH_ERRORS':
      return {
        ...state,
        offers: {
          ...state.offers,
          publishErrors: {
            ...state.offers.publishErrors,
            [action.id]: null,
          },
        },
      };

    case 'DOCUMENTS_LOADING':
      return setPage(state, 'documents', action.query.page, null);

    case 'DOCUMENTS_LOADING_FAILED':
      return setPage(state, 'documents', action.query.page, null, null, null, true);

    case 'DOCUMENTS_LOADED':
    {
      const ids = action.documents.map(document => document.id);
      const records = action.documents.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'documents', action.query.page, ids, action.pageInfo);
      const stateWithRecords = addRecords(stateWithPage, 'documents', records);
      const byHash = action.documents.reduce((h, d) => ({ ...h, [d.hash]: d }), {});

      return putIn(stateWithRecords, ['documents', 'byHash'], {
        ...stateWithRecords.documents.byHash,
        ...byHash,
      });
    }

    case 'DOCUMENT_LOADED':
      return addRecords(state, 'documents', { [action.id]: action.document });

    case 'COMPANIES_LOADING':
      return setPage(state, 'companies', action.query.page, null);

    case 'COMPANIES_LOADED':
    {
      const ids = action.companies.map(offer => offer.id);
      const records = action.companies.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'companies', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'companies', records);
    }

    case 'COMPANY_LOADED':
      return addRecords(state, 'companies', { [action.id]: action.company });

    case 'COMPANY_OFFERS_LOADED':
      return {
        ...state,
        offers: {
          ...state.offers,
          byCompany: {
            ...state.offers.byCompany,
            [action.id]: action.offers,
          },
        },
      };

    case 'EMAIL_TEMPLATES_LOADING':
      return setPage(state, 'emailTemplates', action.query.page, null);

    case 'EMAIL_TEMPLATES_LOADED':
    {
      const ids = action.emailTemplates.map(offer => offer.id);
      const records = action.emailTemplates.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'emailTemplates', action.query.page, ids);

      return addRecords(stateWithPage, 'emailTemplates', records);
    }

    case 'EMAIL_TEMPLATE_LOADED':
      return addRecords(state, 'emailTemplates', { [action.id]: action.emailTemplate });

    case 'EMAIL_TEMPLATE_UPDATED':
      return addRecords(state, 'emailTemplates', { [action.id]: action.emailTemplate });

    case 'ACTIVATION_LOADED':
      return addRecords(state, 'activations', { [action.id]: action.activation });

    case 'RECOMMENDATIONS_LOADING':
      return setPage(state, 'recommendations', action.query.page, null);

    case 'RECOMMENDATIONS_LOADED':
    {
      const ids = action.recommendations.map(recommendation => recommendation.id);
      const records = action.recommendations.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'recommendations', action.query.page, ids);

      return addRecords(stateWithPage, 'recommendations', records);
    }

    case 'CUSTOM_FIELDS_LOADING':
      return setPage(state, 'customFields', action.query.page, null);

    case 'CUSTOM_FIELDS_LOADED':
    {
      const ids = action.customFields.map(customField => customField.id);
      const records = action.customFields.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'customFields', action.query.page, ids);

      return addRecords(stateWithPage, 'customFields', records);
    }

    case 'CUSTOM_FIELD_LOADED':
      return {
        ...state,
        customFields: {
          ...state.customFields,
          records: {
            ...state.customFields.records,
            [action.id]: action.customField,
          },
        },
      };

    case 'CUSTOM_FIELD_REFERENCES_LOADING':

    case 'CUSTOM_FIELD_REFERENCES_LOADED':
      return {
        ...state,
        customFields: {
          ...state.customFields,
          references: {
            ...state.customFields.references,
            [action.id]: action.references,
          },
        },
      };

    case 'CUSTOM_FIELD_TREE_LOADED':
    // FIXME: separate by purpose here!
      return {
        ...state,
        customFields: {
          ...state.customFields,
          byPurpose: action.tree.reduce((bags, field) => field.purpose.reduce((b, p) => ({ ...b, [p]: [...b[p], field] }), bags), {
            signup_field: [],
            activation_field: [],
            company: [],
            dealer: [],
            offer: [],
          }),
        },
      };

    case 'ADDONS_LOADING':

    case 'ADDONS_LOADED':
      return {
        ...state,
        addons: {
          ...state.addons,
          byCompany: {
            ...state.addons.byCompany,
            [action.companyId]: action.addons || null,
          },
        },
      };

    case 'OFFER_ADDONS_LOADING':

    case 'OFFER_ADDONS_LOADED':
      return {
        ...state,
        addons: {
          ...state.addons,
          byOffer: {
            ...state.addons.byOffer,
            [action.offerId]: action.offerAddons || null,
          },
        },
      };

    case 'EMAILS_LOADING':
      return setPage(state, 'emails', action.query.page, null);

    case 'EMAILS_LOADED':
    {
      const ids = action.emails.map(email => email.id);
      const records = action.emails.reduce((r, e) => ({ ...r, [e.id]: e }), {});
      const stateWithPage = setPage(state, 'emails', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'emails', records);
    }

    case 'EMAIL_LOADED':
      return addRecords(state, 'emails', { [action.id]: action.email });

    case 'EMAIL_REMOVE_WEBHOOK':
      {
        const email = {
          ...state.emails.records[action.emailId],
        };

        if (email) {
          email.webhook_id = null;

          return addRecords(state, 'emails', { [action.emailId]: email });
        }
      }

      return addRecords(state, 'emails', { [action.id]: action.email });

    case 'EMAILS_BY_META_LOADED':
      return {
        ...state,
        emails: {
          ...state.emails,
          byMeta: {
            ...state.emails.byMeta,
            [action.key]: {
              ...state.emails.byMeta[action.key],
              [action.value]: action.emails,
            },
          },
        },
      };

    case 'SMS_LOADING':
      return setPage(state, 'sms', action.query.page, null);

    case 'SMS_LOADED':
    {
      const ids = action.sms.map(sms => sms.id);
      const records = action.sms.reduce((acc, sms) => ({ ...acc, [sms.id]: sms }), {});
      const stateWithPage = setPage(state, 'sms', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'sms', records);
    }

    case 'A_SMS_LOADED':
      return addRecords(state, 'sms', { [action.id]: action.sms });

    case 'SMS_MESSAGE_SERVICES_LOADED':
      return { ...state, sendSettings: { sms: action.messageServices } };

    case 'SMS_BY_META_LOADED':
      return {
        ...state,
        sms: {
          ...state.sms,
          byMeta: {
            ...state.sms.byMeta,
            [action.key]: {
              ...state.sms.byMeta[action.key],
              [action.value]: action.sms,
            },
          },
        },
      };

    case 'SMS_BY_RECIPIENT_LOADED':
      return putIn(state, ['sms', 'byRecipient', action.recipient], action.sms);

    case 'PEOPLE_LOADING':
      return setPage(state, 'people', action.query.page, null);

    case 'PEOPLE_LOADED':
    {
      const people = action.response;
      const records = people.reduce((acc, person) => ({ ...acc, [person.id]: person }), {});
      const stateWithPage = setPage(state, 'people', action.query.page, people.map(({ id }) => id), action.pageInfo);

      return addRecords(stateWithPage, 'people', records);
    }

    case 'PERSON_LOADED':
      return addRecords(state, 'people', { [action.id]: action.person });

    case 'SIGNUP_FIELDS_LOADED':
      return {
        ...state,
        signupFields: {
          ...state.signupFields,
          byCompany: {
            ...state.signupFields.byCompany,
            [action.companyId]: action.fields,
          },
        },
      };

    case 'CLEAR_SIGNUP_FIELDS':
      return {
        ...state,
        signupFields: {
          ...state.signupFields,
          byCompany: {
            ...state.signupFields.byCompany,
            [action.companyId]: null,
          },
        },
      };

    default:
      return state || initialState;
  }
}
