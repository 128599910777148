import { push } from 'connected-react-router';

import { GET_PAGE, POST, GET, PATCH, PUT, DELETE } from './data';
import { success } from './flash';

export function loadDealer(accessToken, id) {
  return async (dispatch) => {
    const dealer = await GET(accessToken, `/users/dealers/${id}`);

    dispatch({ type: 'DEALER_LOADED', dealer });
  };
}

export function patchDealer(accessToken, id, patch) {
  return async (dispatch) => {
    await PATCH(accessToken, `/users/dealers/${id}`, patch);
    dispatch(loadDealer(accessToken, id));
    dispatch(success('Dealer has been updated.'));
  };
}

export function patchDealerCompany(accessToken, id, companyId, patch) {
  return async (dispatch) => {
    await PATCH(accessToken, `/users/dealers/${id}/companies/${companyId}`, patch);
    dispatch(loadDealer(accessToken, id));
    dispatch(success('Dealer company has been updated.'));
  };
}

export function addCompanyToDealer(accessToken, id, companyId, payload) {
  return async (dispatch) => {
    await PUT(accessToken, `/users/dealers/${id}/companies/${companyId}`, payload);
    dispatch(loadDealer(accessToken, id));
    dispatch(success('Company Added'));
  };
}

export function removeCompanyFromDealer(accessToken, id, companyId) {
  return async (dispatch) => {
    await DELETE(accessToken, `/users/dealers/${id}/companies/${companyId}`);
    dispatch(loadDealer(accessToken, id));
    dispatch(success('Company Removed'));
  };
}

export function loadDealers(accessToken, requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'DEALERS_LOADING', query });
    const [dealers, pageInfo] = await GET_PAGE(accessToken, '/users/dealers', {}, query);

    dispatch({
      type: 'DEALERS_LOADED', query, dealers, pageInfo,
    });
  };
}

export function createDealer(accessToken, attributes) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/users/dealers', attributes);
    const { id } = await response.json();

    dispatch(push(`/dealers/${id}`));
  };
}

export function loadMyDealer(accessToken) {
  return async (dispatch) => {
    const response = await GET(accessToken, '/users/me/dealer');

    dispatch({ type: 'MY_DEALER_LOADED', dealer: response });
  };
}

export function loadResellerOffers(requestedQuery) {
  return async (dispatch) => {
    const query = { page: 1, ...requestedQuery };

    dispatch({ type: 'RESELLER_OFFERS_LOADING', query });
    const [resellers,pageInfo] = await GET_PAGE(undefined, '/offers/_super/reseller_offers', {}, query);

    dispatch({
      type: 'RESELLER_OFFERS_LOADED', query, resellers, pageInfo,
    });
  };
}

export function patchMyDealer(accessToken, patch) {
  return async (dispatch) => {
    await PATCH(accessToken, '/users/me/dealer', patch);
    dispatch(loadMyDealer(accessToken));
    dispatch(success('Dealer has been updated.'));
  };
}

export function loadMyDealersUsers(accessToken) {
  return async (dispatch) => {
    const response = await GET(accessToken, '/users/me/dealer/users');

    dispatch({ type: 'MY_DEALERS_USERS_LOADED', users: response });
  };
}

export function removeUserFromMyDealer(accessToken, userId) {
  return async (dispatch) => {
    await DELETE(accessToken, `/users/me/dealer/users/${userId}`);
    dispatch(loadMyDealersUsers(accessToken));
    dispatch(success(`User ${userId} removed`));
  };
}

export function createUserForMyDealer(accessToken, body) {
  return async (dispatch) => {
    dispatch({ type: 'MY_DEALERS_USERS_ACTION_BEGIN' });
    try {
      await POST(accessToken, '/users/me/dealer/users', body);
      dispatch({ type: 'MY_DEALERS_USERS_ACTION_SUCCESS' });
      dispatch(loadMyDealersUsers(accessToken));
      dispatch(success('User created'));
    } catch (error) {
      dispatch({ type: 'MY_DEALERS_USERS_ACTION_ERROR', error });
    }
  };
}
