import { TRIGGER_OWNER_TYPE } from 'resources/triggers/constants';

import { putIn } from '../utils';


const initialState = {
  byUser: {},
  byCompany: {},
  byDealer: {},
  global: null,
  triggersHistory: {},
  registeredEvents: null,
};

function pathFor({ owner_type: type, owner_id: id }) {
  switch (type) {
    case TRIGGER_OWNER_TYPE.company:
      return ['byCompany', id];

    case TRIGGER_OWNER_TYPE.user:
      return ['byUser', id];

    case TRIGGER_OWNER_TYPE.dealer:
      return ['byDealer', id];

    case TRIGGER_OWNER_TYPE.global:
      return ['global'];

    default:
      throw new Error(`Unknown or missing owner_type filter: ${type}`);
  }
}

export default function triggersReducer(state, action) {
  switch (action.type) {
    case 'TRIGGERS_LOADING':
      return putIn(state, pathFor(action.filter), null);

    case 'TRIGGERS_LOADED':
      return putIn(state, pathFor(action.filter), action.triggers);

    case 'TRIGGER_EVENTS_LOADED':
      return putIn(state, ['registeredEvents'], action.events);

    case 'TRIGGERS_HISTORY_LOADED': {
      const { triggersHistory: records, pageInfo } = action;

      return putIn(state, ['triggersHistory'], { records, pageInfo });
    }

    default:
      return state || initialState;
  }
}
