const initialState = {
  pages: {},
  records: {},
  pageInfo: {},
};

export default function placeListGroupsReducer(state, action) {
  switch (action.type) {
    case 'PLACE_LIST_GROUP_ITEM_LOADED': {
      return {
        ...state,
        records: {
          ...state.records,
          [action.id]: action.placeListGroupItem,
        },
      };
    }

    case 'PLACE_LIST_GROUP_LOADING':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.query.page]: null,
        },
      };

    case 'PLACE_LIST_GROUP_LOADED': {
      const { placeListGroups, query, pageInfo } = action;
      const ids = placeListGroups.map(property => property.id);
      const records = placeListGroups.reduce((r, o) => ({ ...r, [o.id]: o }), {});

      return {
        ...state,
        records: {
          ...state.records,
          ...records,
        },
        pages: {
          ...state.pages,
          [query.page]: ids,
        },
        pageInfo,
      };
    }

    default:
      return state || initialState;
  }
}
