import * as constants from "./analytics.constants";

const initialState = {
  page: 1,
  perPage: 25,
  total: 0,
  analytics: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.action.ANALYTICS_SHOW_LOADER:
      return { ...state, isLoading: true };

    case constants.action.ANALYTICS_HIDE_LOADER:
      return { ...state, isLoading: false };

    case constants.action.ANALYTICS_SET_PAGE:
      return { ...state, page: action.payload };

    case constants.action.ANALYTICS_SET_TOTAL:
      return { ...state, total: action.payload };

    case constants.action.ANALYTICS_FETCHED:
      return { ...state, analytics: action.payload };

    default:
      return state;
  }
}
