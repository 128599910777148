const initialState = {
  token: null,
};

export default function authReducer(state, action) {
  switch (action.type) {
    case 'AUTHORIZE':
      return initialState;

    case 'SET_AUTH_INFO':
      return { ...state, ...action.info, tokenPending: false, showLogoutWarning: false };

    case 'SET_AUTH_ERROR':
      return { ...state, error: action.error, errorSource: action.source, showLogoutWarning: false };

    case 'PARSE_AUTH_HASH':
      return { ...state, tokenPending: true };

    case 'LOGOUT':
      return initialState;

    case 'ACTIVITY':
      return { ...state, lastActivity: action.now };

    case 'WARN_ABOUT_LOGOUT':
      return { ...state, showLogoutWarning: true };

    default:
      return state || initialState;
  }
}
