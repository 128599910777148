import { stateOptionsFromTransitions } from 'utils';

export const LINKS = {
  IdeCoDocs: 'https://k8s.idecodevsite.com/documents/api/latest',
};

export const UTILITY_TYPE = {
  electricity: 'electricity',
  cable: 'cable',
  internet: 'internet',
  phone: 'phone',
  insurance: 'insurance',
  naturalgas: 'naturalgas',
  furniture: 'furniture',
};

export const UTILITY_TYPE_ONLY_OPTIONS = [
  [UTILITY_TYPE.electricity, 'Electricity'],
  [UTILITY_TYPE.cable, 'TV'],
  [UTILITY_TYPE.internet, 'Internet'],
  [UTILITY_TYPE.phone, 'Phone'],
  [UTILITY_TYPE.insurance, 'Insurance'],
  [UTILITY_TYPE.naturalgas, 'Natural Gas'],
  [UTILITY_TYPE.furniture, 'Furniture'],
];

export const UTILITY_TYPE_OPTIONS_NO_TDU = [
  [UTILITY_TYPE.electricity, 'Electricity'],
  [UTILITY_TYPE.cable, 'TV'],
  [UTILITY_TYPE.internet, 'Internet'],
  [UTILITY_TYPE.phone, 'Phone'],
  [UTILITY_TYPE.insurance, 'Insurance'],
  [UTILITY_TYPE.naturalgas, 'Natural Gas'],
  ['services', 'Services'],
];

export const PHONE_UTILITY_TYPES = {
  landline: 'landline',
  mobile: 'mobile',
  voip: 'voip',
  bulk: 'bulk',
};

export const PHONE_UTILITY_LABEL = {
  [PHONE_UTILITY_TYPES.landline]: 'Landline',
  [PHONE_UTILITY_TYPES.mobile]: 'Mobile',
  [PHONE_UTILITY_TYPES.voip]: 'VOIP',
  [PHONE_UTILITY_TYPES.bulk]: 'Bulk',
};

export const PHONE_UTILITY_OPTIONS = [
  [PHONE_UTILITY_TYPES.landline, PHONE_UTILITY_LABEL[PHONE_UTILITY_TYPES.landline]],
  [PHONE_UTILITY_TYPES.mobile, PHONE_UTILITY_LABEL[PHONE_UTILITY_TYPES.mobile]],
  [PHONE_UTILITY_TYPES.voip, PHONE_UTILITY_LABEL[PHONE_UTILITY_TYPES.voip]],
  [PHONE_UTILITY_TYPES.bulk, PHONE_UTILITY_LABEL[PHONE_UTILITY_TYPES.bulk]],
];

// Cable => TV
export const TV_UTILITY_TYPES = {
  cable: 'cable',
  satellite: 'satellite',
  streaming: 'streaming',
  bulk: 'bulk',
};

export const TV_UTILITY_LABEL = {
  [TV_UTILITY_TYPES.cable]: 'Cable',
  [TV_UTILITY_TYPES.satellite]: 'Satellite',
  [TV_UTILITY_TYPES.streaming]: 'Streaming',
  [TV_UTILITY_TYPES.bulk]: 'Bulk',
};

export const TV_UTILITY_OPTIONS = [
  [TV_UTILITY_TYPES.cable, TV_UTILITY_LABEL[TV_UTILITY_TYPES.cable]],
  [TV_UTILITY_TYPES.satellite, TV_UTILITY_LABEL[TV_UTILITY_TYPES.satellite]],
  [TV_UTILITY_TYPES.streaming, TV_UTILITY_LABEL[TV_UTILITY_TYPES.streaming]],
  [TV_UTILITY_TYPES.bulk, TV_UTILITY_LABEL[TV_UTILITY_TYPES.bulk]],
];

export const INTERNET_UTILITY_TYPES = {
  fiber: 'fiber',
  cable: 'cable',
  dsl: 'dsl',
  satellite: 'satellite',
  hotspot: 'hotspot',
  bulk: 'bulk',
};

export const INTERNET_UTILITY_LABEL = {
  [INTERNET_UTILITY_TYPES.fiber]: 'Fiber',
  [INTERNET_UTILITY_TYPES.cable]: 'Cable',
  [INTERNET_UTILITY_TYPES.dsl]: 'DSL',
  [INTERNET_UTILITY_TYPES.satellite]: 'Satellite',
  [INTERNET_UTILITY_TYPES.hotspot]: 'Hotspot',
  [INTERNET_UTILITY_TYPES.bulk]: 'Bulk',
};

export const INTERNET_UTILITY_OPTIONS = [
  [INTERNET_UTILITY_TYPES.fiber, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.fiber]],
  [INTERNET_UTILITY_TYPES.cable, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.cable]],
  [INTERNET_UTILITY_TYPES.dsl, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.dsl]],
  [INTERNET_UTILITY_TYPES.satellite, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.satellite]],
  [INTERNET_UTILITY_TYPES.hotspot, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.hotspot]],
  [INTERNET_UTILITY_TYPES.bulk, INTERNET_UTILITY_LABEL[INTERNET_UTILITY_TYPES.bulk]],
];

export const OFFER_TYPE = {
  electricity: 'electricity',
  insurance: 'insurance',
  naturalgas: 'naturalgas',
  media: 'media',
  services: 'services',
};

export const OFFER_STATE = {
  template: 'template',
  draft: 'draft',
  published: 'published',
};

export const OFFER_TYPE_OPTIONS = [
  [OFFER_TYPE.electricity, 'Electricity'],
  [OFFER_TYPE.insurance, 'Insurance'],
  [OFFER_TYPE.naturalgas, 'Natural Gas'],
  [OFFER_TYPE.media, 'Media'],
  [OFFER_TYPE.services, 'Services'],
];

export const RECOMMENDATION_TYPE_OPTIONS = OFFER_TYPE_OPTIONS;

export const MEDIA_UTILITY_TYPE_OPTIONS = [
  [UTILITY_TYPE.cable, 'TV'],
  [UTILITY_TYPE.internet, 'Internet'],
  [UTILITY_TYPE.phone, 'Phone'],
];

export const SERVICE_UTILITY_TYPE_OPTIONS = [
  [UTILITY_TYPE.furniture, 'Furniture'],
];

export const INSURANCE_UTILITY_TYPE_OPTIONS = [
  [UTILITY_TYPE.insurance, 'Insurance'],
];

export const DOCUMENTS_KEY = {
  tdu: 'tdu',
  yrac: 'yrac',
  tos: 'tos',
  efl: 'efl',
  rep: 'rep',
};

export const DEFAULT_AGREEMENTS_KEY = {
  poa: 'poa',
  ra: 'ra',
  uha: 'uha',
  dpa: 'dpa',
  sra: 'sra',
};

export const UTILITY_TYPE_OPTIONS = [
  ...UTILITY_TYPE_OPTIONS_NO_TDU,
  ['tdu', DOCUMENTS_KEY.tdu],
];

export const DEFAULT_COMPANY_AGREEMENTS_KEYS = [DEFAULT_AGREEMENTS_KEY.ra, DEFAULT_AGREEMENTS_KEY.uha, DEFAULT_AGREEMENTS_KEY.poa];
export const DEFAULT_DEALER_AGREEMENTS_KEYS = [DEFAULT_AGREEMENTS_KEY.dpa, DEFAULT_AGREEMENTS_KEY.sra];

const residentAgreement = 'By agreeing to this Resident Authorization between you and {{providerName}}, you authorize {{providerName}} to create a new account for you, and you also agree this account will be governed by their Term of Service.';

const electicityUsageAgreement = `
  I ({{residentName}}) authorize {{providerName}} to share usage data with Keyready.

  Our goal is to provide you, the homeowner, the renter, the ultimate bill-payer, with the information you need to manage your energy costs. We request this usage data from {{providerName}}. Not all energy Providers will share data with us but all Providers will require an explicit authorization from you, the account holder, to make that data available to us. That’s why we ask you for an Authorization to read the data.

  By accepting the Authorization request below, you are authorizing {{providerName}} to share data on this account with Keyready.
`;

const powerOfAttorneyAgreement = `
  By its execution below, {{residentName}} (“Customer”) authorizes Ideco.io llc, dba Keyready (Keyready) acting through its managers, officers, or employees, to (i) negotiate one or more service agreements on behalf of Customer with {{providerName}}; (ii) access Customer’s account(s) with {{providerName}} with whom Customer has an account; (iii) communicate with {{providerName}} on behalf of Customer; (iv) obtain copies of Customer’s payment history, billing history, and update or modify Customer’s payment information which is inclusive of but not limited to Customer’s credit card information and Automated Clearing House (ACH) information with {{providerName}} with whom the Customer has an account; and (v) close an active account with {{providerName}} on behalf of Customer.

  By its signature below, Customer hereby appoints Ideco.io, llc dba Keyready as Customer’s agent with limited power of attorney to act on Customer’s behalf in obtaining the information described above and negotiating the agreement(s) described herein.
`;

const dealerAgreement = 'I affirm that I am authorized by {{residentName}} (“Customer”) to place this order.';

const salesRepAgreement = 'I affirm that I am authorized by {{residentName}} (“Customer”) to place this order.';

export const SIGNER_TYPE = {
  myP: 'myP',
  resident: 'resident',
  provider: 'provider',
  dealer: 'dealer',
  salesRep: 'salesRep',
};

export const AGREEMENT_SIGNER = {
  [SIGNER_TYPE.myP]: 'My Providers',
  [SIGNER_TYPE.resident]: 'Resident',
  [SIGNER_TYPE.provider]: 'Provider',
  [SIGNER_TYPE.dealer]: 'Dealer',
  [SIGNER_TYPE.salesRep]: 'Sales Rep',
};

export const DEFAULT_AGREEMENTS = {
  [DEFAULT_AGREEMENTS_KEY.poa]: {
    name: DEFAULT_AGREEMENTS_KEY.poa,
    key: DEFAULT_AGREEMENTS_KEY.poa,
    utilityTypes: [],
    text: powerOfAttorneyAgreement,
    signer: SIGNER_TYPE.resident,
    counterSigner: SIGNER_TYPE.myP,
  },
  [DEFAULT_AGREEMENTS_KEY.ra]: {
    name: DEFAULT_AGREEMENTS_KEY.ra,
    key: DEFAULT_AGREEMENTS_KEY.ra,
    utilityTypes: [],
    text: residentAgreement,
    signer: SIGNER_TYPE.resident,
    counterSigner: SIGNER_TYPE.provider,
  },
  [DEFAULT_AGREEMENTS_KEY.uha]: {
    name: DEFAULT_AGREEMENTS_KEY.uha,
    key: DEFAULT_AGREEMENTS_KEY.uha,
    utilityTypes: [
      UTILITY_TYPE.electricity,
    ],
    text: electicityUsageAgreement,
    signer: SIGNER_TYPE.resident,
    counterSigner: SIGNER_TYPE.myP,
  },
  [DEFAULT_AGREEMENTS_KEY.dpa]: {
    name: DEFAULT_AGREEMENTS_KEY.dpa,
    key: DEFAULT_AGREEMENTS_KEY.dpa,
    utilityTypes: [],
    text: dealerAgreement,
    signer: SIGNER_TYPE.dealer,
    counterSigner: SIGNER_TYPE.provider,
  },
  [DEFAULT_AGREEMENTS_KEY.sra]: {
    name: DEFAULT_AGREEMENTS_KEY.sra,
    key: DEFAULT_AGREEMENTS_KEY.sra,
    utilityTypes: [],
    text: salesRepAgreement,
    signer: SIGNER_TYPE.salesRep,
    counterSigner: SIGNER_TYPE.dealer,
  },
};

export const OFFER_STATE_TRANSITIONS = [
  {
    from: ['draft'],
    to: 'published',
    label: 'Publish',
    color: 'primary',
  },
  {
    from: ['published'],
    to: 'draft',
    label: 'Reset to draft',
    color: 'secondary',
  },
];

export const OFFER_STATE_OPTIONS = [...stateOptionsFromTransitions(OFFER_STATE_TRANSITIONS),
  ['template', 'Template'],
];

export const OFFER_FEE_EVENT_OPTIONS = [
  ['installation', 'Installation'],
];

export const OFFER_EVENT_OPTIONS = [
  'AddonAdded', 'AddonRemoved',
  'CompanyAdded', 'CompanyRemoved',
  'DocumentAdded', 'DocumentRemoved',
  'DistributionChannelAdded', 'DistributionChannelRemoved', 'DistributionChannelChanged',
  'CustomFieldAdded', 'CustomFieldRemoved', 'CustomFieldChanged',
  'FeeAdded', 'FeeRemoved', 'FeeStructureChanged',
  'OfferCloned', 'OfferCreated', 'OfferDeleted', 'OfferUnDeleted', 'OfferChanged',
].map(i => [i, i]);

export const COMPANY_EVENT_OPTIONS = [
  'CompanyCreated', 'CompanyChanged',
  'AddonAdded', 'AddonRemoved',
  'DocumentAdded', 'DocumentRemoved', 'DocumentChanged',
  'SignupFieldAdded', 'SignupFieldRemoved', 'SignupFieldChanged',
  'CompanyFieldAdded', 'CompanyFieldRemoved', 'CompanyFieldChanged',
  'ActivationFieldAdded', 'ActivationFieldRemoved', 'ActivationFieldChanged',
  'DistributionChannelAdded', 'DistributionChannelRemoved',
  'OfferAdded', 'OfferRemoved',
].map(i => [i, i]);

export const ZIP_CODE_REGEX = /^(?:\*|\d{5})$/;

export const REQUIRED_COMPANY_DOCUMENTS = {
  [OFFER_TYPE.electricity]: [DOCUMENTS_KEY.tos, DOCUMENTS_KEY.yrac],
};

export const DOCUMENT_BADGES = {
  yrac: DOCUMENTS_KEY.yrac,
  tos: DOCUMENTS_KEY.tos,
};

export const LANGUAGE_OPTIONS = [
  ['en', 'English'],
  ['es', 'Spanish'],
];

export const FEE_TYPE = {
  RECURRING: 'recurring',
  EVENT: 'event',
};

export const FEE_STRUCTURE = {
  SIMPLE: 'simple',
  CONDITIONAL_FEES: 'conditional_fees',
  CUMULATIVE_FEES: 'cumulative_fees',
  NOT_APPLICABLE: 'not_applicable',
};

export const FEE_TYPE_LABEL = {
  [FEE_STRUCTURE.SIMPLE]: 'Simple Fee',
  [FEE_STRUCTURE.CONDITIONAL_FEES]: 'Conditional Fees',
  [FEE_STRUCTURE.CUMULATIVE_FEES]: 'Cumulative Fees',
  [FEE_STRUCTURE.NOT_APPLICABLE]: 'Not Applicable',
};

export const FEE_STRUCTURE_OPTIONS = [
  [FEE_STRUCTURE.SIMPLE, FEE_TYPE_LABEL[FEE_STRUCTURE.SIMPLE]],
  [FEE_STRUCTURE.CONDITIONAL_FEES, FEE_TYPE_LABEL[FEE_STRUCTURE.CONDITIONAL_FEES]],
  [FEE_STRUCTURE.CUMULATIVE_FEES, FEE_TYPE_LABEL[FEE_STRUCTURE.CUMULATIVE_FEES]],
  [FEE_STRUCTURE.NOT_APPLICABLE, FEE_TYPE_LABEL[FEE_STRUCTURE.NOT_APPLICABLE]],
];

export const CONDITION_OPERATOR_OPTIONS = [
  ['>', 'greater than'],
  ['<', 'less than'],
  ['>=', 'greater than or euqal to'],
  ['<=', 'less than or equal to'],
  ['=', 'equal to'],
];


export const COMPANY_PAYMENT_OPTIONS = [
  ['paper_bill', 'Provider will Coordinate'],
  ['credit_card', 'Credit/Debit Card'],
  ['ach', 'ACH'],
];

export const CUSTOM_FIELD_PURPOSE_OPTIONS = [
  ['signup_field', 'Signup Field'],
  ['activation_field', 'Activation Field'],
  ['company', 'Company Field'],
  ['dealer', 'Dealer Field'],
  ['offer', 'Offer Field'],
];

export const CUSTOM_FIELD_TYPES = {
  TEXT: 'text',
  OBJECT: 'object',
  EMAIL: 'email',
  URL: 'url',
  DATE: 'date',
  DATETIME: 'datetime',
  INSTALLATION: 'installation',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox_group',
  PII: 'pii',
  SELECT: 'select',
  FILE_UPLOAD: 'file_upload',
};

export const CUSTOM_FIELD_TYPE_OPTIONS = [
  [CUSTOM_FIELD_TYPES.TEXT, 'Text'],
  [CUSTOM_FIELD_TYPES.OBJECT, 'Multi-field'],
  [CUSTOM_FIELD_TYPES.EMAIL, 'Email'],
  [CUSTOM_FIELD_TYPES.URL, 'URL'],
  [CUSTOM_FIELD_TYPES.DATE, 'Date'],
  [CUSTOM_FIELD_TYPES.DATETIME, 'Datetime'],
  [CUSTOM_FIELD_TYPES.INSTALLATION, 'Installation'],
  [CUSTOM_FIELD_TYPES.PASSWORD, 'Password'],
  [CUSTOM_FIELD_TYPES.TEXTAREA, 'Text (textarea)'],
  [CUSTOM_FIELD_TYPES.RADIO, 'Radio'],
  [CUSTOM_FIELD_TYPES.CHECKBOX, 'Checkbox'],
  [CUSTOM_FIELD_TYPES.CHECKBOX_GROUP, 'Checkbox Group'],
  [CUSTOM_FIELD_TYPES.PII, 'Personally Identifiable Information (PII)'],
  [CUSTOM_FIELD_TYPES.SELECT, 'Select'],
  [CUSTOM_FIELD_TYPES.FILE_UPLOAD, 'File Upload'],
];

export const AFFILIATE_STATE_OPTIONS = [
  ['estimate', 'Estimate'],
  ['earned', 'Earned'],
  ['missed', 'Missed'],
  ['pending', 'Pending'],
];

export const EMAIL_STATE_FILTER_OPTIONS = [
  ['sent', 'Sent'],
  ['delivered', 'Delivered'],
  ['dropped', 'Dropped'],
  ['error', 'Error'],
  ['with_replies', 'With Replies'],
];

export const SMS_STATE_FILTER_OPTIONS = [
  ['error', 'error'],
  ['accepted', 'accepted'],
  ['queued', 'queued'],
  ['sending', 'sending'],
  ['sent', 'sent'],
  ['receiving', 'receiving'],
  ['received', 'received'],
  ['delivered', 'delivered'],
  ['undelivered', 'undelivered'],
  ['failed', 'failed'],
];

export const DISTRIBUTION_CHANNEL_RULE_TYPE = {
  STATE_NAME: 'state_name',
  CITY_NAME: 'city_name',
  ZIP_CODE_PREFIX: 'zip_code_prefix',
  ZIP_CODE: 'zip_code',
  UTILITY_TYPE: 'utility_type',
  COMPANY_ID: 'company_id',
  DEALER_ID: 'dealer_id',
  PLACE_LIST_ID: 'place_list_id',
  PLACE_LIST_GROUP: 'place_list_groups'
};

export const DISTRIBUTION_CHANNEL_RULE_TYPE_OPTIONS = [
  [DISTRIBUTION_CHANNEL_RULE_TYPE.STATE_NAME, 'State/Province'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.CITY_NAME, 'City'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE_PREFIX, 'Zipcode Prefix'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE, 'Zip Code'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.DEALER_ID, 'Dealer'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.PLACE_LIST_ID, 'Property'],
  [DISTRIBUTION_CHANNEL_RULE_TYPE.PLACE_LIST_GROUP, 'Place List Group'],
];

export const DISTRIBUTION_CHANNEL_RULE_NAME = {
  MATCH: 'match',
  DONT_MATCH: 'dont_match',
  IN_LIST: 'in_list',
};

export const DISTRIBUTION_CHANNEL_RULE = [
  [DISTRIBUTION_CHANNEL_RULE_NAME.MATCH, 'is'],
  [DISTRIBUTION_CHANNEL_RULE_NAME.DONT_MATCH, 'is not'],
  [DISTRIBUTION_CHANNEL_RULE_NAME.IN_LIST, 'in list'],
];

export const DISTRIBUTION_CHANNEL_RULE_OPERATORS_BY_TYPE = {
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE]: DISTRIBUTION_CHANNEL_RULE,
  default: DISTRIBUTION_CHANNEL_RULE.filter(([rule]) => rule !== DISTRIBUTION_CHANNEL_RULE_NAME.IN_LIST),
};

export const DISTRIBUTION_CHANNEL_ENTITY_TYPE = {
  company: 'company',
  dealer: 'dealer',
};

export const RULE_ERRORS = [
  ['hasDoubles', 'Values should be unique'],
  ['isNotUnique', 'Two rules with the same operator key pair will be merged into one rule'],
  ['hasInvalidValues', 'Some values are not acceptable for this key'],
];

export const CONTENT_TYPE_OPTIONS = [
  ['text/csv', 'text/csv'],
  ['application/pdf', 'application/pdf'],
  ['application/yaml', 'application/yaml'],
  ['text/html', 'text/html'],
  ['application/sla', 'application/sla'],
  ['image/png', 'image/png'],
  ['image/jpeg', 'image/jpeg'],
  ['image/svg+xml', 'image/svg+xml'],
];

export const REQUIRED_OFFER_DOCUMENT_KEYS = {
  [OFFER_TYPE.electricity]: ['efl'],
};

export const IMPORT_SOURCE_TYPE = {
  RentManager: 'rentmanager',
  Entrata: 'entrata',
  Resman: 'resman',
  Spreadsheet: 'spreadsheet',
  Offers: 'offers',
};

export const IMPORT_SOURCE_TYPE_OPTIONS = [
  ['rentmanager', 'Rentmanager'],
  ['entrata', 'Entrata'],
  ['resman', 'Resman'],
  ['offers', 'Offers'],
  ['kafka', 'Kafka'],
  ['udm', 'UDM'],
  ['activebuilding', 'ActiveBuilding'],
  ['rei', 'REI'],
  ['onesite', 'OneSite'],
  [IMPORT_SOURCE_TYPE.Spreadsheet, 'Spreadsheet'],
];

export const IMPORT_SOURCE_KIND = {
  POLLING: 'polling',
  WEBHOOK: 'webhook',
};

export const IMPORT_SOURCE_KIND_OPTIONS = [
  [IMPORT_SOURCE_KIND.POLLING, 'Polling'],
  [IMPORT_SOURCE_KIND.WEBHOOK, 'Webhook'],
];


export const IMPORT_SOURCE_APPROVAL = [
  [false, 'Automatic'],
  [true, 'Manual'],
];

export const CRYPT_DATA_OWNER_TYPE_OPTIONS = [
  ['tenant', 'Tenant'],
];

export const CRYPT_DATA_DISCLOSE_TO_TYPE_OPTIONS = [
  ['user', 'User'],
  ['company', 'Company'],
];

export const PERSON_STATE_OPTIONS = [
  'Lead',
  'Prospect',
  'Approved',
  'Withdrawn',
  'Denied',
  'Resident',
  'Moved-Out',
  'Unknown',
].map(s => [s, s]);

export const PROPERTY_TYPE_OPTIONS = [
  ['unit', 'Unit'],
  ['property', 'Property'],
];


export const PERSON_STATE_BADGE_COLORS = {
  Lead: 'dark',
  Prospect: 'primary',
  Approved: 'success',
  Withdrawn: 'danger',
  Denied: 'danger',
  Resident: 'info',
  'Moved-out': 'dark',
  Unknown: 'light',
};

export const PERSON_PROCESS_STATE_OPTIONS = [
  'New',
  'Added',
  'Approved',
  'Denied',
  'Current Resident',
  'Missing Information',
  'Record Conflict',
  'Missed Opportunity',
  'Invalid Record',
  'Old',
].map(k => [k, k]);

export const PERSON_PROCESS_STATE_BADGE_COLORS = {
  New: 'light',
  Added: 'info',
  Old: 'dark',
  Approved: 'success',
  Denied: 'danger',
  'Current Resident': 'light',
  'Missing Information': 'warning',
  'Record Conflict': 'danger',
  'Missed Opportunity': 'warning',
  'Invalid Record': 'warning',
};

export const ADDON_TYPE_OPTIONS = [
  ['normal', 'Normal'],
  ['quantity', 'Quantity'],
  ['select', 'Select'],
];

export const REG_EXP_VALIDATORS = {
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE]: /^\d{5}$/,
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE_PREFIX]: /^\d{3}$/,
  UUID: /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-(8|9|a|b|A|B)[0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$/,
};

export const REG_EXP_DELIMETERS = {
  [DISTRIBUTION_CHANNEL_RULE_TYPE.ZIP_CODE]: /[,\W]+/,
  UUID: /[,\s;|/]+/,
};

export const DETAILS_ENTITY_TYPE = {
  place: 'place',
  placeList: 'placeList',
  person: 'person',
};

export const DETAILS_TYPE_OPTIONS = [
  [DETAILS_ENTITY_TYPE.place, 'Place'],
  [DETAILS_ENTITY_TYPE.placeList, 'Place List'],
  [DETAILS_ENTITY_TYPE.person, 'Person'],
];

export const ENTITY_TYPES = {
  PLACE: 'place',
  PERSON: 'person',
  PLACE_LIST: 'place-list',
};

export const ENTITY_SETTINGS = {
  [ENTITY_TYPES.PLACE]: {
    key: 'place_id',
    detailsPath: 'placeList.places',
  },
  [ENTITY_TYPES.PERSON]: {
    key: 'person_id',
    detailsPath: 'persons',
  },
  [ENTITY_TYPES.PLACE_LIST]: {
    key: 'place_list_id',
    detailsPath: 'placeList',
  },
};

export const USER_PERMISSIONS = {
  DECRYPT_DATA: 'decrypt:private-data',
  CREATE_USERS: 'create:users',
  UPDATE_USERS: 'update:users',
  READ_USERS: 'read:users',
};

export const SPREADSHEET_OPTIONS = {
  FILE: 'File',
  URL: 'URL',
  S3: 'S3',
};