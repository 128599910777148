import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import activations from 'resources/activations/activations.reducer';
import serviceTypes from 'resources/serviceTypes/serviceTypes.reducer';
import urlShortsReducer from 'resources/url-shorts/url-shorts.reducer';
import analytics from 'resources/analytics/analytics.reducer';
import auth from './auth';
import data from './data';
import autocomplete from './autocomplete';
import flash from './flash';
import email from './email';
import users from './users';
import distributionChannels from './distributionChannels';
import affiliate from './affiliate';
import documents from './documents';
import importReducer from './import';
import properties from './properties';
import crypt from './crypt';
import assignments from './assignments';
import offerEvents from './offerEvents';
import companyEvents from './companyEvents';
import triggers from './triggers';
import dealers from './dealers';
import zipcodeGroups from './zipcodeGroups';
import authorizationAgreements from './authorizationAgreements';
import placeList from './placeList';
import persons from './persons';
import placeListGroups from './placeListGroups';

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  data,
  flash,
  autocomplete,
  email,
  users,
  distributionChannels,
  affiliate,
  documents,
  import: importReducer,
  properties,
  urlShorts: urlShortsReducer,
  crypt,
  assignments,
  offerEvents,
  companyEvents,
  triggers,
  dealers,
  activations,
  zipcodegroups: zipcodeGroups,
  agreements: authorizationAgreements,
  placeList,
  serviceTypes,
  persons,
  analytics,
  placeListGroups
});
