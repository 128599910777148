import { createBrowserHistory } from 'history';

if (process.env.NODE_ENV === 'test') {
    window.ENV = {
      BACKEND_BASE_URL: 'fake',
      AUTH0_DOMAIN: 'fake',
      AUTH0_CLIENT_ID: 'fake',
      AUTH0_AUDIENCE: 'fake',
      BASENAME: '/'
    };
  } 

export default createBrowserHistory({ basename: window.ENV.BASENAME });