import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import * as authActions from '../../../actions/auth';
import { companyLogoUrl } from '../../../actions/data';
import { filterNav } from '../../../utils';
import idecoLogo from '../../../assets/src/svg/ideco.svg';
// sidebar nav config
import navigation from '../../../nav';
// routes config
import Breadcrumbs from './Breadcrumbs';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

function formatExpiresIn(n) {
  if (n < 0) {
    return 'The past';
  }
  const hours = Math.floor(n / (3600 * 1000));
  const minutes = Math.ceil((n - hours * 3600 * 1000) / (60 * 1000));
  let result = '';

  if (hours > 0) {
    result += `${hours} h, `;
  }
  result += `${minutes} m`;

  return result;
}

AppSidebarNav.prototype.activeRoute = function(routeName, props) {
  return props.location.pathname.indexOf(routeName) === 0
    ? 'nav-item nav-dropdown open'
    : 'nav-item nav-dropdown';
}

class DefaultLayout extends Component {
  static contextTypes = {
    accessScope: PropTypes.arrayOf(PropTypes.string),
    accessLimits: PropTypes.object,
  };

  render() {
    const { profile, logout, expiresAt } = this.props;
    const { accessScope } = this.context;
    const now = new Date().getTime();
    const expiresAtTime = Date.parse(expiresAt);
    const expiresIn = formatExpiresIn(expiresAtTime - now);
    const nav = filterNav(navigation, accessScope);
    const { accessLimits } = this.context;
    const singleCompanyId = accessLimits.company_id && accessLimits.company_id.length === 1 && accessLimits.company_id[0];
    const logo = singleCompanyId ? companyLogoUrl(singleCompanyId) : idecoLogo;
    const logoHeight = accessLimits.company_id ? 55 : 40;

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader profile={profile} expiresIn={expiresIn} logout={logout} history={this.props.history} logo={logo} logoHeight={logoHeight} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={nav} location={this.props.location} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Breadcrumbs />
            <Container fluid>
              {this.props.children}
              {/* <Switch> */}
              {/* {this.props.children} */}
              {/* <Redirect from="/" to="/dashboard" /> */}
              {/* </Switch> */}
            </Container>
          </main>
          {/* <AppAside fixed hidden>
            <DefaultAside />
          </AppAside> */}
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default withRouter(connect(
  state => state.auth,
  { ...authActions },
)(DefaultLayout));
